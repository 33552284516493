import swh from '@/assets/sw_helpers.js'

export default class MeteorShowerManager {
  constructor(stel) {
    this.stel = stel
    this.layer = null
    this.meteorShower = null
    this.visible = false
    
    this.showers = {
      'PER': { name: 'Perseid Meteor Shower', culturalName: '英仙座流星雨', ra: 48, dec: 58, peak: '8月13日', zhr: 100 },
      'GEM': { name: 'Geminid Meteor Shower', culturalName: '双子座流星雨', ra: 112, dec: 32.2, peak: '12月14日', zhr: 120 },
      'QUA': { name: 'Quadrantid Meteor Shower', culturalName: '象限仪座流星雨', ra: 230.1, dec: 48.5, peak: '1月4日', zhr: 120 },
      'LEO': { name: 'Leonid Meteor Shower', culturalName: '狮子座流星雨', ra: 154, dec: 21.8, peak: '11月17日', zhr: 15 },
      'LYR': { name: 'Lyrid Meteor Shower', culturalName: '四月天琴座流星雨', ra: 271, dec: 34, peak: '4月22日', zhr: 18 },
      'ETA': { name: 'Eta Aquariid Meteor Shower', culturalName: '宝瓶座η流星雨', ra: 338, dec: -1, peak: '5月6日', zhr: 40 },
      'DEL': { name: 'Delta Aquariid Meteor Shower', culturalName: '宝瓶座δ流星雨', ra: 340, dec: -16.4, peak: '7月30日', zhr: 20 },
      'ORI': { name: 'Orionid Meteor Shower', culturalName: '猎户座流星雨', ra: 95, dec: 15.5, peak: '10月21日', zhr: 20 },
      'STA': { name: 'Southern Taurid Meteor Shower', culturalName: '金牛座南流星雨', ra: 32, dec: 8.7, peak: '10月10日', zhr: 5 },
      'NTA': { name: 'Northern Taurid Meteor Shower', culturalName: '金牛座北流星雨', ra: 58, dec: 22.7, peak: '11月12日', zhr: 5 },
      'URS': { name: 'Ursid Meteor Shower', culturalName: '小熊座流星雨', ra: 217, dec: 74.8, peak: '12月22日', zhr: 10 }
    }
  }

  init() {
    this.layer = this.stel.createLayer({
      z: 7,
      visible: true
    })
    this.setVisibility(false)
  }

  createShower(code) {
    if (this.meteorShower) {
      this.layer.remove(this.meteorShower)
    }

    const shower = this.showers[code]
    if (!shower) return

    this.meteorShower = this.stel.createObj('dso', {
      id: 'meteor_radiant_' + code,
      model: 'dso',
      names: [shower.name], 
      culturalNames: [shower.culturalName],  // 使用中文名称
      model_data: {
        ra: shower.ra,
        de: shower.dec,
        vmag: 1,
        dimx: 0,
        dimy: 0,
      },
      types: ['Met'],
      info: {
        peak: shower.peak,
        zhr: shower.zhr,
      }
    })
    
    this.layer.add(this.meteorShower)
    if (this.visible) {
      this.setVisibility(true)
    }
  }


  setVisibility(visible) {
    this.visible = visible
    if(this.layer) {
      this.layer.visible = visible
      if(visible && this.meteorShower) {
        // 使用 swh 方法设置选择对象
        
        swh.setSweObjAsSelection(this.meteorShower)
      }
    }
  }


  destroy() {
    if(this.layer) {
      this.layer.remove(this.meteorShower)
      this.meteorShower = null
      this.layer = null
    }
  }
}